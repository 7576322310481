.campaign-container {
  .campaign-select-block {
    display: flex;
    align-items: center;
    .revert-button {
      margin-left: 8px;
      color: #ffffff;
      background: #E16E04;
      border: none !important;
      text-transform: unset;

      &[disabled] {
        background: #c4c4c4;
        color: #000000;
      }
    }
  }

  .campaign-message-block {
    display: flex;
    .campaign-override-message {
      width: 400px;
      margin-right: 8px;
    }

    .revert-message {
      color: #000000;
      margin-top: 4px;
    }
  }
}

.submit-button-message {
  display: inline-block;
  margin-left: 16px;
  color: red;
}

.note {
  text-transform: none;
  color: red;
  margin-top: 4px;
  display: block;

  .highlight-text {
    font-weight: bold;
    font-style: italic;
  }
}
