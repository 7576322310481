.grid-container-audiences {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 0.1fr 0.2fr 4fr;
  gap: 1px 1px;
  grid-template-areas: "navbar navbar navbar navbar" "headerarea headerarea headerarea headerarea" "formarea formarea formarea formarea";

  background: #FFFFFF;
  border: 1px solid #E6E7E7;
  overflow: auto;
  width: 100%;
}

.navbar {
  display: grid;
  grid-template-columns: 1.5fr .06fr;
  grid-template-rows: 1fr;
  gap: 1px 1px;
  grid-template-areas: "ttdlogo logout";
  grid-area: navbar;
  width: 100%;
  height: 56px;
  background: #0099FA;
}

.ttdlogo {
  margin-left: 1%;
  margin-top: .7%;
  height: 16px;
}

.applogout {
  margin-top: 21%;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.headerarea {
  grid-area: headerarea;
  margin-left: 2%;
}

.formarea {
  grid-area: formarea;
  width: 100%;
  height: 100%;
  padding-left: 2%;
}

.loginarea {
  grid-area: formarea;
  width: 100%;
  height: 100%;
  margin-top: 5%;
  margin-left: 40%;

}

.table {
  margin-left: 2%;
  border: none;
  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1px;
  /* neutral / elephant */
  color: #9A9A9A;
}

.tr {
  height: 50px;
}

/* Data */
.welcome {
  display: flex;
  font-family: Avenir;
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 33px;
  /* identical to box height */
  color: #313232;
  text-align: right;
}


/* Data */
.heading {
  display: flex;
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  /* identical to box height */
  color: #313232;
}

.hypelink {
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 1px;
  /* neutral / elephant */
}

/* Explore */
.explore {
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  /* neutral / elephant */
  color: #9A9A9A;

}

/* Line */
.line {
  display: flex;
  border: 1px solid #EDEEED;
  width: 100%;
}

.highlight {
  text-decoration: none;
  border-bottom: 5px solid #F6B73D;
}

input[type=password], input[type=text], select {
  font-family: unset;
  width: 400px;
  padding: 10px 8px;
  margin: 4px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.info {
  background-color: #e7f3fe;
  border-left: 6px solid #2196F3;
}

.datepicker-container__content {
  display: inline-grid;
}
