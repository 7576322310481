.input-number {
  margin-top: 4px;

  input[type=number] {
    font-family: unset;
    padding: 10px 4px 10px 8px;
    margin-bottom: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      opacity: 1;
    }

    &:disabled {
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        opacity: 0.5;
      }
    }
  }

  .suffix {
    display: inline-block;
    margin-left: 8px;
  }
}
